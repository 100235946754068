import React, { FC } from 'react'
import { AboutBank, LegalEntities, ForIndividuals, InfoDisclosure } from '@features'
import { PageLayout } from '@widgets'
import { TMainPageProps, TabAlias } from './types'

export const MainPage: FC<TMainPageProps> = ({
  headerFooter,
  aboutBank,
  legalEntities,
  forIndividuals,
  infoDisclosure,
  tab,
}) => (
  <PageLayout {...headerFooter} active={tab}>
    {
      {
        [TabAlias.ABOUT_BANK]: <AboutBank {...aboutBank} />,
        [TabAlias.LEGAL_ENTITIES]: <LegalEntities {...legalEntities} />,
        [TabAlias.FOR_INDIVIDUALS]: <ForIndividuals {...forIndividuals} />,
        [TabAlias.INFO_DISCLOSURE]: <InfoDisclosure {...infoDisclosure} />,
      }[tab]
    }
  </PageLayout>
)
