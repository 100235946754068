import { TAboutBank, TForIndividuals, TInfoDisclosure, TLegalEntities } from '@features'
import { TPageLayout } from '@widgets'

export enum TabAlias {
  ABOUT_BANK = '',
  LEGAL_ENTITIES = 'yuridicheskim-licam',
  FOR_INDIVIDUALS = 'fizicheskim-licam',
  INFO_DISCLOSURE = 'raskrytie-informacii',
}

export type TMainPageData = {
  aboutBank: TAboutBank
  legalEntities: TLegalEntities
  forIndividuals: TForIndividuals
  infoDisclosure: TInfoDisclosure
  headerFooter: TPageLayout
  pages: {
    pagePaths: string[]
  }
  tab?: string
}

export type TMainPageProps = TMainPageData & {
  tab?: string
}
