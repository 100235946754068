import { GetStaticPaths, GetStaticProps } from 'next'
import { REVALIDATE_TIME } from '@shared/config'
import { getCommonRequest } from '@shared/api'
import { CONSUL_HEADER_FOOTER, CONSUL_MAIN_PAGE, CONSUL_PAGES } from '@shared/constants'
import { TMainPageData, TMainPageProps } from './types'

type TParams = {
  page: string
}

export const getMainPageStaticPaths: GetStaticPaths = async () => {
  const {
    data: { pagePaths },
  } = await getCommonRequest<TMainPageData['pages']>(CONSUL_PAGES, {
    next: { revalidate: REVALIDATE_TIME },
  })

  return {
    paths: pagePaths.map((page) => ({
      params: { page: [page] },
    })),
    fallback: false,
  }
}

export const getMainPageStaticProps: GetStaticProps<TMainPageProps, TParams> = async ({ params }) => {
  const [{ data: pageData }, { data: headerFooter }] = await Promise.all([
    getCommonRequest<Omit<TMainPageProps, 'headerFooter'>>(CONSUL_MAIN_PAGE, {
      next: { revalidate: REVALIDATE_TIME },
    }),
    getCommonRequest<TMainPageProps['headerFooter']>(CONSUL_HEADER_FOOTER, {
      next: { revalidate: REVALIDATE_TIME },
    }),
  ])

  if (!pageData) {
    throw new Error(`Consul: Received page data is null`)
  }
  if (headerFooter === null) {
    throw new Error(`Consul: Received header-footer data is null`)
  }

  return {
    props: { ...pageData, headerFooter, tab: params?.page?.[0] ?? '' },
    revalidate: REVALIDATE_TIME,
  }
}
