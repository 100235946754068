import React, { FC, useMemo, useState } from 'react'
import { MoreButton } from '@entities'
import { Document, Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TInfoDisclosure } from '../types'
import { Styled } from '../styled'
import { DocumentGroup } from './DocumentGroup'

const MAX_DOCUMENTS_COUNT = 10

export const InfoDisclosure: FC<TInfoDisclosure> = ({ title, docGroups, titleLoadingMore = 'Загрузить ещё' }) => {
  const [active, setActive] = useState(0)

  const [isOpen, setIsOpen] = useState(false)
  const isShowMore = useMemo(() => docGroups[active].documents.length > MAX_DOCUMENTS_COUNT, [active, docGroups])

  const [evenColumns, oddColumns] = useMemo(() => {
    const data = isOpen ? docGroups[active].documents : docGroups[active].documents.slice(0, MAX_DOCUMENTS_COUNT)
    const result = [[], []]

    data.forEach((item, index) => result[index % 2].push(item))

    return result
  }, [active, isOpen, docGroups])

  return (
    <>
      <Text {...theme.header} marginBottomMob={theme.spacings.md2}>
        {title}
      </Text>
      <Styled.Container>
        <Styled.LeftContainer>
          {docGroups.map(({ title, documents }, index) => (
            <DocumentGroup
              title={title}
              key={index}
              isActive={index === active}
              onClick={() => setActive(index)}
              documents={documents}
              titleLoadingMore={titleLoadingMore}
            />
          ))}
        </Styled.LeftContainer>
        <Styled.DocumentsContainer>
          <Styled.DocumentItem>
            {evenColumns.map((document, index) => (
              <Document key={index} {...document} />
            ))}
          </Styled.DocumentItem>
          {Boolean(oddColumns.length) && (
            <Styled.DocumentItem>
              {oddColumns.map((document, index) => (
                <Document key={index} {...document} />
              ))}
            </Styled.DocumentItem>
          )}
          {isShowMore && !isOpen && (
            <Styled.MoreContainer>
              <MoreButton onClick={() => setIsOpen(true)}>{titleLoadingMore}</MoreButton>
            </Styled.MoreContainer>
          )}
        </Styled.DocumentsContainer>
      </Styled.Container>
    </>
  )
}
